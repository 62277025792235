import { template as template_748ef30e7e374c7294680f08ca7f5079 } from "@ember/template-compiler";
const SidebarSectionMessage = template_748ef30e7e374c7294680f08ca7f5079(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
