import { template as template_9d2389a1d7fa4756a6745c854cb904d9 } from "@ember/template-compiler";
const FKText = template_9d2389a1d7fa4756a6745c854cb904d9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
