import { template as template_23548c3b40064f4699653e2e31392a47 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_23548c3b40064f4699653e2e31392a47(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
